import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[child-element]',
    standalone: true
})
export class ChildElementDirective {

    get el() { return this.element.nativeElement as HTMLElement }

    /**
     * The data representing the item the menu was opened for.
     */
    @Input("child-element") data: HTMLElement;

    constructor(
        private readonly element: ElementRef
    ) { }

    ngAfterViewInit() {
        this.ngOnChanges();
    }

    ngOnChanges() {
        this.ngOnDestroy();

        if (!this.data) return;
        if (!(this.data instanceof HTMLElement)) {
            throw new Error("Directive ChildElement must be an HTML Element!");
        }
        // TODO: If this.data is a string, we could just assign it to innerHTML.

        this.el.append(this.data);
    }

    ngOnDestroy() {
        this.el.innerHTML = '';
    }
}
