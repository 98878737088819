<div class="background"></div>
<div class="background alt"></div>

<div
    class="container"
>
    <div class="image-container">
        <!-- Profile image (cached) -->
        <div [child-element]="picture"></div>
        @if (user.status == 'blocked') {
            <div class="status"><mat-icon [matTooltip]="(user.name || user.email) + ' has been explicitly banned from logging in to this tenant'">info</mat-icon>Blocked</div>
        }
        @else if (!user.invited) {
            <div class="status"><mat-icon [matTooltip]="(user.name || user.email) + ' has not been invited to tenant'">info</mat-icon>Not Invited</div>
        }
        @else if (!user.isActive) {
            <div class="status"><mat-icon [matTooltip]="(user.name || user.email) + ' is not active in this tenant'">info</mat-icon>Inactive</div>
        }
    </div>

    <div class="text-container">
        <p class="title-text">{{user.name || user.email}}</p>
        <p class="info-text">
            {{user.title}}
            ({{user.persona}})
        </p>
        <p class="info-email">
            <i>{{user.email}}</i>
        </p>


        @if (user.lastActive) {
            <p class="info-lastseen">Last Seen {{user.lastActive.toLocaleString()}}</p>
        }
        @else {
            <p class="info-lastseen">User has not logged in before.</p>
        }

        <div class="notification-icons">
            Notified by:
            @if (preferences?.notifyDetails?.useEmail) {
            <svg matTooltip="User receives notifications via Email" xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" viewBox="0 0 24 24" fill="var(--text-color)">
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                    d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z" />
            </svg>
            }
            @if (preferences?.notifyDetails?.useSlack) {
            <svg matTooltip="User receives notifications via Slack" width="24" height="24" viewBox="0 0 127 127" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M27.2 80c0 7.3-5.9 13.2-13.2 13.2C6.7 93.2.8 87.3.8 80c0-7.3 5.9-13.2 13.2-13.2h13.2V80zm6.6 0c0-7.3 5.9-13.2 13.2-13.2 7.3 0 13.2 5.9 13.2 13.2v33c0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V80z"
                    fill="#E01E5A" />
                <path
                    d="M47 27c-7.3 0-13.2-5.9-13.2-13.2C33.8 6.5 39.7.6 47 .6c7.3 0 13.2 5.9 13.2 13.2V27H47zm0 6.7c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H13.9C6.6 60.1.7 54.2.7 46.9c0-7.3 5.9-13.2 13.2-13.2H47z"
                    fill="#36C5F0" />
                <path
                    d="M99.9 46.9c0-7.3 5.9-13.2 13.2-13.2 7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H99.9V46.9zm-6.6 0c0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V13.8C66.9 6.5 72.8.6 80.1.6c7.3 0 13.2 5.9 13.2 13.2v33.1z"
                    fill="#2EB67D" />
                <path
                    d="M80.1 99.8c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2-7.3 0-13.2-5.9-13.2-13.2V99.8h13.2zm0-6.6c-7.3 0-13.2-5.9-13.2-13.2 0-7.3 5.9-13.2 13.2-13.2h33.1c7.3 0 13.2 5.9 13.2 13.2 0 7.3-5.9 13.2-13.2 13.2H80.1z"
                    fill="#ECB22E" />
            </svg>
            }

            <!-- If the user doesn't have any email/slack preferences turned on -->
            @if (!preferences ||
                !preferences.notifyDetails ||
                (
                    !preferences.notifyDetails.useEmail &&
                    !preferences.notifyDetails.useSlack
                )
                ) {

                <svg matTooltip="User will receive no notifications" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="var(--text-color)">
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path
                        d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm0-15.5c2.49 0 4 2.02 4 4.5v.1l2 2V11c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68c-.24.06-.47.15-.69.23l1.64 1.64c.18-.02.36-.05.55-.05zM5.41 3.35L4 4.76l2.81 2.81C6.29 8.57 6 9.74 6 11v5l-2 2v1h14.24l1.74 1.74 1.41-1.41L5.41 3.35zM16 17H8v-6c0-.68.12-1.32.34-1.9L16 16.76V17z" />
                </svg>
            }
        </div>
    </div>
</div>


<div class="profile-btn">
    <a [href]="'#/navigator/memberships?$scope=user.' + user.id">
        <button mat-flat-button>View profile</button>
    </a>
    <a [href]="'#/navigator/ActiveTasks?$scope=user.' + user.id">
        <button mat-flat-button>View task list</button>
    </a>
</div>
