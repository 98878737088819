/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import {
    DTO, DTORef, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI, Attachment
} from '../dto';
import { OrganizationType } from './organizationtype';

export interface Organization_v1 extends DTO, DTOMethods<Organization_v1> {
    orgType: OrganizationType;
    children: DTORef[];
    content: Attachment[];
    members: DTORef[];
    resolvedUsers: Attachment[];
}
export const Organization_v1: DTOMetaData & DTOClassMethods<Organization_v1> = {
    _label:'Organizations',
    _dto: 'Organization_v1',
    _type: 'organization',
    _colors: { primaryThemeColor: "#c2ac19", secondaryThemeColor: "#c2ac19", tertiaryThemeColor: "#c2ac19" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
        { label: 'IDX (External Identifier)', prop: 'idx', hint: 'ID of this organization', type: 'string', fixed: true, source: null, values: undefined },
        { label: 'Organization type', prop: 'orgType', hint: 'Organization type', type: 'enum', required: true, fixed: true, source: undefined, values: ["COMPANY", "DIVISION", "DEPARTMENT", "TEAM"] },
     ],
    _related: [
        { prop: 'children', type: 'organization', class: 'Organization_v1', label: 'Organizations' },
        { prop: 'applications', type: 'application', class: 'Application _v1', label: 'Applications' },
        { prop: 'platforms', type: 'platform', class: 'Platform_v1', label: 'Platforms' }
     ],
    _endpoint: '/api/eom/v1.0/organization/v1.0',
    _parentDTONames: 'Organization_v1',
    _childrenAt: 'children', // children,platforms,applications',
    _icon: './assets/dtos/organization.svg',
    _docLink: '/wiki/elevate/Assets/organization/',
    endpointAPI: new BoundEndpointAPI<Organization_v1>('Organization_v1', DTO),
    from: (obj: any): Organization_v1 => {
        return Object.setPrototypeOf(obj, Organization_v1._prototype);
    },
    select: (p: any = ''): any => {
        return Organization_v1.endpointAPI.get(p).then(res =>
            !res ? undefined
            : Array.isArray(res) ? res.map(a => Organization_v1.from(a) as Organization_v1)
            : Organization_v1.from(res)
    )}
}
